<template>
  <div class="dress">
    <v-container>
      <v-row>
        <v-switch
          v-model="showFilters"
          label="Mostrar filtros"
          class="mt-0 mb-3"
          color="secondary"
          hide-details
        ></v-switch>
        <v-spacer></v-spacer>
        <v-btn class="mx-2" fab right color="secondary" small @click="reload">
          <v-icon> mdi-reload </v-icon>
        </v-btn>
      </v-row>
    </v-container>

    <v-card class="mb-2" v-if="showFilters">
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="3" v-if="role == 'SUPERADMIN'">
            <v-select
              outlined
              :items="offices"
              item-text="name"
              item-value="id"
              label="Seleccione Oficina"
              no-data-text="No hay registros..."
              hide-details
              dense
              v-model="filters.office"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="3" v-if="role == 'SUPERADMIN'">
            <v-select
              outlined
              :items="categories"
              item-text="name"
              item-value="id"
              label="Seleccione Categoria"
              no-data-text="No hay registros..."
              hide-details
              dense
              v-model="filters.category"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-text-field
              label="Código"
              outlined
              hide-details
              dense              
              v-model="filters.code"              
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-btn block color="secondary" @click="searchFilters">
              Buscar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Busqueda"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="this.$store.state.dresses.dresses.data"
        :search="search"
        hide-default-footer
        :loading="this.$store.state.categories.isLoading"
        loading-text="Cargando... Por favor espere"
        no-data-text="No hay registro"
        no-results-text="Lo sentimos no hay registro para su busqueda, intente con otro"
        :items-per-page="20"
      >
        <template v-slot:[`item.rental_value`]="item">
          {{ item.item.rental_value | formatNumber }}
        </template>
        <template v-slot:[`item.category`]="item">
          {{ item.item.category.name }}
        </template>
        <template v-slot:[`item.status`]="item">
          <v-chip
            class="ma-2"
            :color="getStatusColor(item.item.status)"
            text-color="white"
            small
          >
            {{ getStatusLan(item.item.status) }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="item">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                @click="show(item.item.id)"
                class="px-1"
              >
                mdi-eye-outline
              </v-icon>
            </template>
            <span>VER</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                @click="edit(item.item)"
                class="px-1"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>EDITAR</span>
          </v-tooltip>
          <v-tooltip bottom v-if="item.item.status !== 'SOLD'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                :color="item.item.status == 'ACTIVE' ? 'red' : 'success'"
                dark
                v-bind="attrs"
                v-on="on"
                class="px-1"
                @click="changeStatus(item.item)"
              >
                {{
                  item.item.status == "ACTIVE" ? "mdi-lock" : "mdi-lock-open-variant"
                }}
              </v-icon>
            </template>
            <span>
              {{ item.item.status == "ACTIVE" ? "INACTIVAR" : "ACTIVAR" }}
            </span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-col cols="12" sm="12" md="12">
        <v-pagination
          v-model="pagination.current"
          :length="pagination.total"
          :total-visible="8"
          circle
          @input="nextPage"
        ></v-pagination>
      </v-col>
    </v-card>

    <!-- Button active dialog -->
    <v-btn class="mx-2" fab right bottom fixed color="secondary" @click="open">
      <v-icon dark> mdi-plus </v-icon>
    </v-btn>

    <!-- Create or edit dialog -->
    <v-dialog
      v-model="dialog"
      :width="currentDress.image ? '900' : '500'"
      persistent
    >
      <v-card>
        <v-card-title class="headline" v-if="isShow">
          Vestido:
          <span class="pl-2">
            <b>{{ model.code }}</b>
          </span>
        </v-card-title>

        <v-card-title class="headline" v-else>
          {{ currentDress ? "Editar" : "Crear" }} vestido
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="py-4">
          <v-row>
            <v-col cols="6" v-if="currentDress.image">
              <v-img :src="model.url" class="pa-5 mb-5"></v-img>
            </v-col>
            <v-col :cols="currentDress.image ? '6' : '12'">
              <v-form ref="form" v-model="validForm" lazy-validation>
                <v-text-field
                  v-model="model.code"
                  :rules="[rules.required]"
                  label="Codigó"
                  required
                  :readonly="isShow"
                ></v-text-field>

                <v-autocomplete
                  label="Categoria"
                  :items="this.$store.state.categories.categories"
                  item-text="name"
                  item-value="id"
                  v-model="model.category_id"
                  :rules="[rules.required]"
                  :readonly="isShow"
                ></v-autocomplete>

                <v-text-field
                  v-model="model.size"
                  label="Talla"
                  required
                  :readonly="isShow"
                ></v-text-field>

                <v-text-field
                  v-model="model.color"
                  label="Color"
                  required
                  :readonly="isShow"
                ></v-text-field>

                <v-text-field
                  v-model="model.rental_value"
                  :rules="[rules.required]"
                  label="Valor alquiler"
                  required
                  :readonly="isShow"
                ></v-text-field>

                <v-file-input
                  label="Imagen"
                  prepend-icon="mdi-file-image"
                  v-model="model.image"
                  v-if="!isShow"
                ></v-file-input>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="py-3">
          <v-spacer></v-spacer>
          <v-btn color="error" text class="mr-4" small @click="clear">
            {{ isShow ? "Cerrar" : "Cancelar" }}
          </v-btn>
          <v-btn
            :disabled="!validForm"
            color="success"
            class="mr-4"
            small
            text
            @click="save"
            v-if="!isShow"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- change status dialog -->
    <v-dialog v-model="dialogChangeStatus" width="500" persistent>
      <v-card>
        <v-card-text class="py-4" v-if="dialogChangeStatus">
          Seguro que desea
          <b>{{ currentDress.status == "ACTIVE" ? "INACTIVAR" : "ACTIVAR" }}</b>
          este vestido?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text class="mr-4" @click="clear" small>
            Cancelar
          </v-btn>
          <v-btn
            color="success"
            class="mr-4"
            @click="changeStatusConfirmation"
            small
            text
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { SET_DRESSES } from "@/store/dresses";
import { SET_OFFICES } from "@/store/offices";
import { SET_CATEGORIES } from "@/store/categories";
import { SET_ALERT } from "@/store/alert";
import { mapGetters } from "vuex";
export default {
  name: "Users",
  filters: {
    formatNumber(value) {
      return new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
      }).format(value);
    },
  },
  data: () => ({
    search: "",
    showFilters: false,
    headers: [
      {
        text: "Código",
        align: "start",
        filterable: true,
        value: "code",
      },
      {
        text: "Talla",
        align: "start",
        filterable: true,
        sortable: false,
        value: "size",
      },
      {
        text: "Color",
        align: "start",
        filterable: true,
        sortable: true,
        value: "color",
      },
      {
        text: "Valor de alquiler",
        align: "start",
        filterable: false,
        sortable: true,
        value: "rental_value",
      },
      {
        text: "Categoria",
        align: "start",
        filterable: true,
        sortable: true,
        value: "category",
      },
      { text: "Estado", value: "status", filterable: false, sortable: false },
      {
        text: "Acciones",
        value: "actions",
        filterable: false,
        sortable: false,
      },
    ],
    filters: {
      office: "",
      code: "",
      category: "",
    },
    pagination: {
      current: 0,
      total: 0,
    },
    dialog: false,
    dialogChangeStatus: false,
    validForm: true,
    currentDress: {
      image: null,
    },
    model: {
      code: "",
      size: "",
      color: "",
      rental_value: "",
      category_id: "",
      image: null,
    },
    rules: {
      required: (value) => !!value || "Campo obligatorio.",
    },
    isShow: false,
    offices: [],
    categories: [],
  }),
  created() {
    this.$store.dispatch("getDresses");
    this.$store.dispatch("getCategoriesAll");
    this.$store.dispatch("getOfficesAll");
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === SET_DRESSES) {
        this.pagination.current = state.dresses.dresses.current_page;
        this.pagination.total = state.dresses.dresses.last_page;
      }
      if (mutation.type === SET_OFFICES) {
        this.offices = state.offices.offices;
      }
      if (mutation.type === SET_CATEGORIES) {
        this.categories = state.categories.categories;
      }
    });
  },
  watch: {
    dialog: function () {
      if (this.dialog == false) this.clear();
    },
  },
  computed: {
    ...mapGetters(["getStatusLan", "getStatusColor"]),
    role() {
      return this.$store.state.user.user.role;
    },
  },
  methods: {
    reload() {
      this.searchSaleInput = "";
      this.filters = {
        office: "",
      };
      this.showFilters = false;
      this.$store.dispatch("getDresses");
    },
    searchFilters() {
      this.searchFiltersText = "";

      if (this.filters.office !== "") {
        this.searchFiltersText += "office=" + this.filters.office + "&";
      }

      if (this.filters.code !== "") {
        this.searchFiltersText += "code=" + this.filters.code + "&";
      }

      if (this.filters.category !== "") {
        this.searchFiltersText += "category=" + this.filters.category + "&";
      }

      if (this.searchFiltersText == "") {
        this.$store.commit(SET_ALERT, {
          message: "Ingrese un valor de busqueda",
          type: "info",
        });
        return;
      }

      this.showFilters = false;
      this.$store.dispatch("getDresses", this.searchFiltersText);
    },
    nextPage() {
      this.$store.dispatch("nextPageDresses", this.pagination.current);
    },
    show(id) {
      this.$store.dispatch("getDress", id).then((dress) => {
        this.model = {
          code: dress.code,
          size: dress.size,
          color: dress.color,
          rental_value: dress.rental_value,
          category_id: dress.category_id,
          url: dress.url,
        };
        this.isShow = true;
        this.dialog = true;
        this.currentDress = dress;
      });
    },
    save() {
      if (!this.$refs.form.validate()) return;

      if (Object.keys(this.currentDress).length > 1) {
        this.model._method = "put";
        this.$store.dispatch("updateDress", {
          form: this.createFormData(),
          id: this.currentDress.id,
        });
      } else {
        this.$store.dispatch("createDress", this.createFormData());
      }
      this.clear();
    },
    changeStatus(user) {
      this.currentDress = user;
      this.dialogChangeStatus = true;
    },
    changeStatusConfirmation() {
      this.$store.dispatch("updateDress", {
        form: {
          status: this.currentDress.status == "ACTIVE" ? "INACTIVE" : "ACTIVE",
          _method: "put",
        },
        id: this.currentDress.id,
      });
      this.clear();
    },
    open() {
      this.dialog = true;
      this.isShow = false;
    },
    edit(dress) {
      this.currentDress = dress;
      this.model = {
        code: dress.code,
        size: dress.size,
        color: dress.color,
        rental_value: dress.rental_value,
        category_id: dress.category_id,
      };
      this.open();
    },
    clear() {
      this.dialog = false;
      this.dialogChangeStatus = false;
      if (this.model.code !== "") this.$refs.form.resetValidation();
      this.model = {
        code: "",
        size: "",
        color: "",
        rental_value: "",
        category_id: "",
      };
      this.currentDress = {
        image: null,
      };
    },
    createFormData() {
      let formData = new FormData();
      Object.entries(this.model).forEach(([key, value]) => {
        if(value !== "" && value !== null){
          formData.append(key, value);
        }        
      });
      return formData;
    },
  },
};
</script>
